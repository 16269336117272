import React, { Fragment, useEffect } from "react";

import { navigate } from "gatsby";

import { getRedirectUrl, removeRedirectUrl } from "@/account/redirect";
import { setAccessToken, setRefreshToken } from "@/account/user";
import { marketingUserVkAuthorizationHandler } from "@/marketing/utils";
import { isBrowser } from "@/utils/env";

const VkAuth = () => {
  // todo: maybe move this to hook
  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    const urlParams = window.location.search.replace(/^\?/, "");
    fetch(`${process.env.GATSBY_API_URL}users/auth/callback/vk?${urlParams}`, {
      headers: {
        "Content-type": "application/json",
        Cookies: document.cookie,
      },
      credentials: "include",
    })
      .then((response) =>
        response.json())
      .then((response) => {
        setAccessToken(response.access_token);
        setRefreshToken(response.refresh_token);
        marketingUserVkAuthorizationHandler();
        const redirectUrl = getRedirectUrl() ?? "/";
        removeRedirectUrl();
        navigate(redirectUrl);
      });
  }, []);

  return null;
};

export default VkAuth;
